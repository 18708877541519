import './App.css';
import numbro from 'numbro'
import Chart from '../components/Chart'
import styled, { keyframes } from 'styled-components'
import '../constants/erc20'
import { getHistoryPrice } from '../hooks/useHistoryPrice'
import { countdown } from '../utils/'
import { projectConfig } from '../constants/config'
import lbPic from '../assets/images/global_bottom1.png'
import rbPic from '../assets/images/global_bg2.png'

import Twitter from '../assets/images/twitter.svg'
import Telegram from '../assets/images/telegram.svg'
import Discord from '../assets/images/discord.svg'
import Medium from '../assets/images/medium.svg'
import GitHub from '../assets/images/gitHub.svg'
import Youtube from '../assets/images/youtube.svg'
import hainPic from '../assets/images/hain.svg'
import peckPic from '../assets/images/peck.svg'
import loadingPic from '../assets/images/loading.png'

import { useMemo, useState } from 'react';

const Title = styled.h3`
  font-size: 21px;
  font-weight: 600;
  color: #fff;
  margin: 10px 0 30px;
  letter-spacing: 1px;
  @media (max-width: 1024px) {
    margin: 10px 20px 30px;
  }
`
const Wrapper = styled.div`
  display: flex;
  grid-template-columns: repeat(12,minmax(0,1fr));
  max-width: 1024px;
  margin: 0 auto;
  > div: first-child {
    position: relative;
    min-height: 360px;
    max-width: 600px;
    flex: 1;
    height: 400px !important;
    border-radius: 6px;
    background: rgba(24, 31, 43, 0.5);
    margin-right: 25px;
    border: 1px double transparent;
    background-clip: padding-box, border-box;
    background-image: linear-gradient(#0d1f2f, #0d1f2f),linear-gradient(180deg, #353848, #6A708B)!important;
    background-origin: border-box;
    .bn {
      color: #6A708B;
      font-size: 14px;
    }
  }
  > div: last-child {
    width: 400px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  @media (max-width: 1024px) {
    display: block;
    > div: first-child {
      max-width: 100%;
      margin: 0 20px;
    }
    > div: last-child {
      display: block;
      // width: 100%;
      margin: 25px 20px;
      width: -webkit-fill-available;
      > div {
        width: 100%;
      }
    }
  }
`
const Card = styled.div`
  width: 188px;
  height: 187px;
  // background: rgba(24, 31, 43, 0.5);
  border-radius: 6px;
  
  margin-bottom: 25px;
  padding-top: 35px;
  line-height: 20px;
  letter-spacing: 1px;
  border: 1px double transparent;
  background-clip: padding-box, border-box;
  background-image: linear-gradient(#0d1f2f, #0d1f2f),linear-gradient(180deg, #353848, #6A708B)!important;
  background-origin: border-box;
`
const Text = styled.p`
  font-size: 14px;
  color: #fff;
  margin-top: 16px;
`
const CardTitle = styled.h6`
  font-size: 18px;
  color: #FFFFFF;
  margin-top: 5px;
  > span {
    font-size: 12px;
    font-weight: 400;
  }
`
const Buy = styled.button`
  padding: 10px 0;
  border-radius: 6px;
  font-weight: 600;
  width: 200px;
  font-size: 15px;
  color: #fff;
  border: 0;
  outline: none;
  margin-top: 20px;
  cursor: pointer;
  background: transparent;
  border: 1px solid #fff;
  height: 41px;
  box-shadow: 0 0 12px rgb(62 176 253 / 65%) inset, 0 0 8px rgb(62 176 253 / 80%);
  &:hover {
    background: linear-gradient(to right, #06DFAF, #0186EF);
    box-shadow: 0 0 12px rgb(62 176 253 / 65%) inset, 0 0 8px rgb(62 176 253 / 80%);
  }
`
const LinkWrapper = styled.div`
  max-width: 1024px;
  margin: 60px auto;
`
const Link = styled.a`
  height: 41px;
  border: 1px solid #6A708B;
  display: block;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  line-height: 41px;
  margin-top: 8px;
  border-radius: 6px;
  text-decoration: none;
  margin: 10px auto;
  &:hover{
    border: 1px solid #505361;
    color: #c3c3c3;
  }
  @media (max-width: 1024px) {
    max-width: 100%;
    width: -webkit-fill-available !important; 
    margin: 10px 20px;
  }
`
const Logo = styled.div`
  text-align: left;
  max-width: 1024px;
  margin: 30px auto;
  @media (max-width: 1024px) {
    margin: 30px 20px;
  }
`
const ChartRemarks = styled.div`
  font-weight: 500;
  color: #69708D;
  line-height: 15px;
  font-size: 12px;
  text-align: left;
  max-width: 420px;
  margin: 40px auto;
  p {
    margin: 10px 0;
  }
  > p: first-child, > p: last-child {
    &::before {
      display: inline-block;
      content: '';
      width: 50px;
      position: relative;
      top: -3px;
      margin-right: 10px;
    }
  }
  > p: first-child {
    &::before {
      background: linear-gradient(90deg,#3F70FF,#06DFAF);
      height: 2px;
    }
  }
  > p: last-child {
    &::before {
      border-bottom: 2px dashed #fff;
    }
  }
  @media (max-width: 1024px) {
    margin: 40px 20px;
  }
`

const Footer = styled.footer`
  display: flex;
  width: 100%;
  max-width: 1024px;
  margin: 100px auto 0;
  text-align: left;
  padding-bottom: 20px;
  @media (max-width: 1024px) {
    display: none
  }
  div:first-child {
    flex: 1;
    a {
      margin-right: 10px;
    }
  }
  > div:last-child {
    color: #6A708B;
    img {
      margin-left: 22px;
      position: relative;
      top: 8px;
    }
    .peckPic {
      top: 6px;
    }
    > .contact {
      color: #6A708B;
      line-height: 39px;
      margin-left: 22px;
    }
  }
  
`
const MobileFooter = styled.footer`
  display: none;
  margin-top: 100px;
  text-align: center;
  color: #6A708B;
  padding-bottom: 20px;
  @media (max-width: 1024px) {
    display: block;
  }
  > div > a {
    margin: 10px;
  }
  > div > p {
    margin-bottom: 10px;
  }
  .footer-left {
    margin: 30px 0;
  }
  a {
    color: #6A708B;
  }
`

const LFireWrapper = styled.div`
  position: absolute;
  z-index: -1;
  left: 0;
  bottom: 0;
  text-align: left;
  img {
    margin-bottom: -5px
  }
`
const RFireWrapper = styled.div`
  position: absolute;
  z-index: -1;
  right: 0;
  bottom: 0;
  text-align: right;
  img {
    margin-bottom: -5px
  }
`
const ButtonWrapper = styled.div`
  > a {
    margin: 0 10px;
  }
`
const pulse = keyframes`
  0% { transform: scale(1); }
  60% { transform: scale(1.1); }
  100% { transform: scale(1); }
`
const AnimatedImg = styled.div`
  animation: ${pulse} 800ms linear infinite;
  & > * {
    width: 48px;
  }
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -18px;
  margin-left: -24px;
  z-index: 999999
`
const Loading = styled.div`
  position: absolute;
  background: rgba(0,0,0,0.3);
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 888888
`

function  App() {
  const [isLoading, setIsLoading] = useState(true)
  const [price, setPrice] = useState(0)
  const [endDate, setEndDate] = useState(0)
  const [endBlock, setEndBlock] = useState(0)
  const [historyPrice, setHistoryPrice] = useState({
    swaps: []
  })
  
  useMemo(() => {
    getHistoryPrice().then(res => {
      setIsLoading(false)
      const _swaps = []
      // console.log(res.swaps.length % 10)
      for (let i = 0; i < res.swaps.length; i ++) {
        if (i % 10 === 0) {
          _swaps.push(res.swaps[i])
        }
      }
      if (res.swaps.length % 10 !== 0 && Number(projectConfig.endBlockNum) === Number(res.swaps[res.swaps.length - 1].blockNum)) {
        _swaps.push(res.swaps[res.swaps.length - 1])
      }
      setHistoryPrice({
        swaps: _swaps
      })
      if (res.swaps.length > 0) {
        setPrice(Number(res.swaps[res.swaps.length - 1].price))
        setEndBlock(Number(res.swaps[res.swaps.length - 1].blockNum))
        const endUnix = (projectConfig.endBlockNum - Number(res.swaps[res.swaps.length - 1].blockNum)) * 13.5 * 1000
        const endDate = new Date().valueOf() + endUnix
        // console.log(new Date().valueOf(), 'now')
        // console.log(new Date(endDate).valueOf())
        setEndDate(endDate)
        // console.log()
        // console.log(res.swaps[res.swaps.length - 1].blockNum)
      } else {
        setPrice(0.1)
      }
    })
  }, [])

  // const historyPrice = await useHistoryPrice()
  // console.log(useHistoryPrice, '---------')
  return (
    <div className="App">
      
      <LFireWrapper>
        <img width="50%" src={lbPic} alt=""/>
      </LFireWrapper>
      <RFireWrapper>
        <img width="30%" src={rbPic} alt=""/>
      </RFireWrapper>
      <Logo>
        <a href="https://www.divergence-protocol.com/">
          <img width="180px" src={require('../assets/images/logo.svg').default} alt="icon"/>
        </a>
      </Logo>
      <Title>DIVER LIQUIDITY BOOTSTRAPPING POOL</Title>
      <Wrapper>
        <div>
          {
            isLoading && <Loading>
              <AnimatedImg>
                <img src={loadingPic} alt="loading-icon" />
              </AnimatedImg>
            </Loading>
          }
          <Chart historyPrice={historyPrice.swaps}/>
        </div>
        <div>
          <Card>
            <img width="53px" src={require('../assets/images/time.png').default} alt="icon"/>
            {
              new Date().valueOf() < new Date(projectConfig.startDate).valueOf() ? <Text>LBP START IN</Text> : <Text>LBP END IN</Text>
            }
            {
              endBlock >= projectConfig.endBlockNum ? <CardTitle>Ended</CardTitle> : <CardTitle>{countdown(new Date(projectConfig.startDate), new Date(endDate))}</CardTitle>
            }
          </Card>
          <Card>
            <img width="53px" src={require('../assets/images/valuation.png').default} alt="icon"/>
            <Text>VALUATION</Text>
            <CardTitle>{numbro((price * 1000000000).toFixed(0)).format({thousandSeparated: true})} <span> USDC</span></CardTitle>
          </Card>
          <Card>
            <img width="53px" src={require('../assets/images/price.png').default} alt="icon"/>
            <Text>LATEST PRICE</Text>
            <CardTitle>{price.toFixed(4)} <span> USDC</span></CardTitle>
          </Card>
          <Card>
            <img width="53px" src={require('../assets/images/market_cap.png').default} alt="icon"/>
            <Text>MARKET CAP</Text>
            <CardTitle>{numbro((price * 77500000).toFixed(0)).format({thousandSeparated: true})} <span> USDC</span></CardTitle>
          </Card>
        </div>
      </Wrapper>
      <ChartRemarks>
        <p>The solid line illustrates the history of DIVER LBP Price</p>
        <p>The dashed line represents the future price if no one buys DIVER</p>
      </ChartRemarks>

      <ButtonWrapper>
        <a target="_blank" rel="noreferrer" href="https://app.balancer.fi/#/trade/0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48/0xfb782396c9b20e564a64896181c7ac8d8979d5f4">
          <Buy>
            Buy DIVER
          </Buy>
        </a>
        <a target="_blank" rel="noreferrer" href="https://docs.divergence-protocol.com/diver-token/token-economics">
          <Buy>
            Read Tokenomics
          </Buy>
        </a>
      </ButtonWrapper>
      
      <LinkWrapper>
        <Link target="_blank" rel="noreferrer" href ="https://pools.balancer.exchange/#/pool/0x53f863e835722533c5832944da75a69f78f0018e" style={{width: '407px'}}>Balancer Pool Information</Link>
        <Link target="_blank" rel="noreferrer" href="https://medium.com/divergence-protocol/introducing-divergence-7fb73ae8a0a4" style={{width: '336px'}}>Introducing Divergence</Link>
        <Link target="_blank" rel="noreferrer" href="https://link.medium.com/x8019BwyJjb" style={{width: '432px'}}>Balancer LBP Details & User Gudie</Link>
      </LinkWrapper>
      <Footer>
        <div className="footer-left">
          <a target="_blank" rel="noreferrer" href="https://twitter.com/divergencedefi" target="_blank" rel="noreferrer">
            <img src={Twitter} alt="twitter"/>
          </a>
          <a target="_blank" rel="noreferrer" href="https://t.me/divergenceprotocol" target="_blank" rel="noreferrer">
            <img src={Telegram} alt="telegram"/>
          </a>
          <a target="_blank" rel="noreferrer" href="https://discord.com/invite/v96fhsPfRa" target="_blank" rel="noreferrer">
            <img width="32px" height="32px" src={Discord} alt="discord"/>
          </a>
          <a target="_blank" rel="noreferrer" href="https://medium.com/divergence-protocol" target="_blank" rel="noreferrer">
            <img src={Medium} alt="medium"/>
          </a>
          <a target="_blank" rel="noreferrer" href="https://www.youtube.com/channel/UC1P8arVH_nvOFudbtXs5i8A" target="_blank" rel="noreferrer">
            <img width="32px" height="32px" src={Youtube} alt="youtube"/>
          </a>
          <a target="_blank" rel="noreferrer" href="https://github.com/DivergenceProtocol" target="_blank" rel="noreferrer">
            <img src={GitHub} alt="github"/>
          </a>
        </div>
        <div>
          <span>Audited by</span>
          <a target="_blank" rel="noreferrer" href="https://chainsulting.de/" target="_blank" rel="noreferrer">
            <img height="28px" src={hainPic} alt="hainPic"/>
          </a>
          <a target="_blank" rel="noreferrer" href="https://peckshield.cn/en" target="_blank" rel="noreferrer">
            <img className="peckPic" height="22px" src={peckPic} alt="peckPic"/>
          </a>
          &nbsp;&nbsp;&nbsp;&nbsp;
          {/* <a href="mailto:contact@divergence-protocol.com" target="_blank" rel="noreferrer">
            Disclaimer
          </a> */}
          <a className="contact" href="mailto:contact@divergence-protocol.com" target="_blank" rel="noreferrer">
            Contact
          </a>
        </div>
      </Footer> 
      <MobileFooter>
        <div>
          <p>Audited by</p>
          <a href="https://chainsulting.de/" target="_blank" rel="noreferrer">
            <img height="28px" src={hainPic} alt="hainPic"/>
          </a>
          <a href="https://peckshield.cn/en" target="_blank" rel="noreferrer">
            <img className="peckPic" height="22px" src={peckPic} alt="peckPic"/>
          </a>
        </div>
        <div className="footer-left">
          <a href="https://twitter.com/divergencedefi" target="_blank" rel="noreferrer">
            <img src={Twitter} alt="twitter"/>
          </a>
          <a href="https://t.me/divergenceprotocol" target="_blank" rel="noreferrer">
            <img src={Telegram} alt="telegram"/>
          </a>
          <a href="https://discord.com/invite/v96fhsPfRa" target="_blank" rel="noreferrer">
            <img width="32px" height="32px" src={Discord} alt="discord"/>
          </a>
          <a href="https://medium.com/divergence-protocol" target="_blank" rel="noreferrer">
            <img src={Medium} alt="medium"/>
          </a>
          <a href="https://www.youtube.com/channel/UC1P8arVH_nvOFudbtXs5i8A" target="_blank" rel="noreferrer">
            <img width="32px" height="32px" src={Youtube} alt="youtube"/>
          </a>
          <a href="https://github.com/DivergenceProtocol" target="_blank" rel="noreferrer">
            <img src={GitHub} alt="github"/>
          </a>
        </div>
        <a className="contact" href="mailto:contact@divergence-protocol.com" target="_blank" rel="noreferrer">
          Contact
        </a>
      </MobileFooter>
    </div>
  );
}

export default App;
